import React, { useState, useRef, useCallback } from 'react'
import { Link } from '@kogk/gatsby-theme-base'
import {
  MenuDesktop,
  MenuMobile,
  CartQuickView,
  Button,
  ButtonLink,
  Burger
} from '@cmp/site'
import { SearchInputSection } from '@cmp/sections'
import { useLanguage } from '@kogk/gatsby-plugin-i18n'
import Logo from '@src/assets/logos/LH-logo-small.svg'
import LogoDesktop from '@src/assets/logos/LH-logo-large.svg'
import cn from 'classnames'
import { useGlobalData } from '@src/data/global-data'
import { Fade } from 'react-reveal'
import { useCartActions, useCartState } from '@src/context/Cart'
import useOnClickOutside from '@root/src/hooks/useOnClickOutside'
import { useCartVisibility } from '@root/src/context/CartVisibility'
import { useFavorites } from '@src/context/Favorites'

const processedDesktopMenu = menu => {
  const subMenu = menu.length > 6 ? menu.slice(6, menu.length) : null
  const mainMenu = menu.length > 6 ? menu.slice(0, 6) : menu
  return { subMenu, mainMenu }
}

const Primary = ({ isSnyrtivara, isFrontpage }) => {
  const { t } = useLanguage()
  const [showSearchBar, setShowSearchBar] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const cartVisibility = useCartVisibility()
  const { cart, cartSize } = useCartState()
  const { changeQuantity } = useCartActions()
  const { mainMenu } = useGlobalData()
  const cartButtonRef = useRef()
  const searchButtonRef = useRef()
  const quickviewRef = useRef()
  const searchInputRef = React.createRef()

  const favorites = useFavorites()

  const favBtnClasses = cn('navbar__favorites mx-1', {
    'navbar__favorites--full': favorites.hasFavorites
  })

  const toggleSearchBar = ref => {
    setShowSearchBar(!showSearchBar)
    if (showSearchBar !== true) {
      ref.focus()
    }
  }

  const icon = favorites.hasFavorites ? 'heartFull' : 'heart'

  const onClickOutsideQuickview = useCallback(
    e => {
      // we need to also check the cart button, otherwise it will behave strangely
      if (!cartButtonRef.current || !cartButtonRef.current.contains(e.target)) {
        cartVisibility.close()
      }
    },
    [cartButtonRef, cartVisibility]
  )

  const onClickOutsideSearchBar = useCallback(
    e => {
      if (
        !searchButtonRef.current ||
        !searchButtonRef.current.contains(e.target)
      ) {
        setShowSearchBar(false)
      }
    },
    [searchButtonRef]
  )

  useOnClickOutside(quickviewRef, onClickOutsideQuickview)
  useOnClickOutside(searchInputRef, onClickOutsideSearchBar)

  const menu = processedDesktopMenu(mainMenu)
  const mobileMenu = mainMenu
  const subMenu = menu.subMenu

  const theme = isSnyrtivara ? 'navbar--black' : 'navbar--primary'

  return (
    <div className='relative'>
      <nav className={cn(theme, 'navbar w-100 d-flex justify-content-between')}>
        <div className='d-none d-xl-flex align-items-center'>
          <Link to='/' title={t`go home`} className='d-none d-xl-inline'>
            <img
              alt='logo'
              height='40'
              width='190'
              className='navbar__logo'
              src={LogoDesktop}
            />
          </Link>
        </div>

        <div className='col-12 col-lg d-flex align-items-center justify-content-center px-0'>
          <div className='d-flex d-xl-none w-100 justify-content-between align-items-center'>
            <span className='d-flex'>
              <Burger active={isOpen} onClick={() => setIsOpen(!isOpen)} />
              <ButtonLink
                type='mini'
                icon='user'
                href='/lyfjagatt'
                className='navbar__user-login mx-1'
              />
            </span>

            <Link to='/' title={t`go home`}>
              <img alt='logo' className='navbar__logo' src={Logo} />
            </Link>

            <span className='d-flex'>
              <ButtonLink
                type='mini'
                icon={icon}
                href='/minar-vorur'
                className={favBtnClasses}
              />
              <ButtonLink
                className='ml-1 navbar__icon-btn'
                icon='cart'
                type='mini-ghost'
                to='/karfa'
              >
                {cartSize > 0 && (
                  <span className='the-button__chart-item-count'>
                    {cartSize}
                  </span>
                )}
              </ButtonLink>
            </span>
          </div>

          <div className='d-none d-xl-block'>
            <div className='d-flex'>
              <MenuDesktop
                items={menu}
                // removing title for now
                // title={t`top level links`}
                subMenu={subMenu}
              />
            </div>
          </div>
        </div>

        <div className='d-flex justify-content-end position-relative'>
          <div className='d-none d-xl-flex'>
            {!isFrontpage && (
              <Button
                ref={searchButtonRef}
                type='mini'
                icon='search'
                className='mx-1 navbar__icon-btn'
                onClick={() => toggleSearchBar(searchInputRef.current)}
              />
            )}
            <ButtonLink
              type='mini-ghost'
              label='Lyfseðlarnir þínir'
              icon='user'
              className='d-none d-xl-flex removeUnderline navbar__lyfjagatt-button mx-1'
              to='/lyfjagatt'
            />
            <ButtonLink
              type='mini'
              icon={icon}
              href='/minar-vorur'
              className={favBtnClasses}
            />
            <Button
              ref={cartButtonRef}
              onClick={cartVisibility.toggle}
              className='ml-1 navbar__icon-btn'
              icon='cart'
              type='mini'
            >
              {cartSize > 0 && (
                <span className='the-button__chart-item-count'>{cartSize}</span>
              )}
            </Button>
          </div>

          <div ref={quickviewRef}>
            <Fade
              when={cartVisibility.isOpen}
              duration={150}
              top
              distance='5px'
            >
              <div
                className={cn('navbar__cart col-12 col-xl-4', {
                  'navbar__cart--visible': cartVisibility.isOpen,
                  'navbar__cart--empty': cartSize === 0
                })}
              >
                {cartVisibility.isOpen && (
                  <CartQuickView
                    isEmpty={cartSize === 0}
                    cart={cart}
                    onClick={cartVisibility.close}
                    onChangeQuantity={changeQuantity}
                    isSnyrtivara={isSnyrtivara}
                  />
                )}
              </div>
            </Fade>
          </div>
        </div>
      </nav>
      {!isFrontpage && (
        <div
          className={cn('navbar__search-section-open', {
            'navbar__search-section-hidden': !showSearchBar
          })}
        >
          <SearchInputSection ref={searchInputRef} className='bg--cyan' />
        </div>
      )}
      {isOpen && (
        <MenuMobile
          className='d-flex d-xl-none'
          menu={mobileMenu}
          subMenu={subMenu}
          isSnyrtivara={isSnyrtivara}
        />
      )}
    </div>
  )
}

export default Primary
